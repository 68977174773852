'use client';

import { analyticsTrack } from '@/util/analytics';
import { Box } from '@magicschool/ui/Box';
import Link from '@mui/material/Link';
import { FormattedMessage } from 'react-intl';
export const LegalInfoClient = ({
  location
}: {
  location: 'SignUp' | 'SignIn' | 'RoleStep';
}) => {
  return <Box mb={1} textAlign="center" data-sentry-element="Box" data-sentry-component="LegalInfoClient" data-sentry-source-file="LegalInfoClient.tsx">
      <FormattedMessage id="auth.agree-to-terms" values={{
      privacyA: text => <Link href="https://www.magicschool.ai/privacy-policy" color="secondary" target="_blank" rel="noopener noreferrer" onClick={() => {
        analyticsTrack(`${location}:PrivacyTermsClicked`, {
          value: 'privacy'
        });
      }}>
              {text}
            </Link>,
      termsA: text => <Link href="https://www.magicschool.ai/terms-of-service" color="secondary" target="_blank" rel="noopener noreferrer" onClick={() => {
        analyticsTrack(`${location}:PrivacyTermsClicked`, {
          value: 'terms'
        });
      }}>
              {text}
            </Link>
    }} data-sentry-element="FormattedMessage" data-sentry-source-file="LegalInfoClient.tsx" />
    </Box>;
};