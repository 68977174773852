'use client';

import { analyticsTrack } from '@/util/analytics';
import { logger } from '@magicschool/logger';
import type { AuthProviderType } from '@magicschool/supabase/types';
import { Box } from '@magicschool/ui/Box';
import { Button } from '@magicschool/ui/Buttons/Button';
import { LoadingButton } from '@magicschool/ui/Buttons/LoadingButton';
import Typography from '@mui/material/Typography';
import { useStore } from 'features/store';
import capitalize from 'lodash-es/capitalize';
import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import type React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { supportsEmailProvider } from './constants';
const providerImageMap: Record<AuthProviderType, React.ReactNode> = {
  google: <Image src="/icons/social-signin-logos/google.svg" width={18} height={18} alt="Google Logo" />,
  azure: <Image src="/icons/social-signin-logos/azure.svg" width={20} height={20} alt="Microsoft Logo" />,
  edlink: <Image src="/icons/social-signin-logos/sso.svg" width={25} height={25} alt="Generic SSO Logo" />,
  email: null
};
function friendlyProviderName(provider: string): React.ReactNode {
  if (provider === 'edlink') return 'SSO';
  if (provider === 'azure') return 'Microsoft';
  return capitalize(provider);
}
export const SSOClient: React.FC<{
  buttonAction: string;
  next: string;
  page: 'signin' | 'signup';
}> = ({
  buttonAction,
  next,
  page
}) => {
  const [pending, setPending] = useState<AuthProviderType | null>(null);
  const router = useRouter();
  const pathname = usePathname();
  const {
    signinWithProvider,
    authProviders,
    reset
  } = useStore(({
    AuthStoreData: as,
    AuthFormStoreData: af
  }) => ({
    signinWithProvider: as.signinWithProvider,
    authProviders: af.authProviders,
    reset: af.reset
  }), []);
  useEffect(() => {
    const key = page === 'signin' ? 'SignIn:AvailableMethods' : 'SignUp:AvailableMethods';
    analyticsTrack(key, {
      type: authProviders
    });
  }, [analyticsTrack, authProviders, page]);
  const onClick = async (provider: AuthProviderType) => {
    try {
      setPending(provider);
      await signinWithProvider(provider, next);
    } catch (err) {
      logger.error('Error signing in with provider', provider, err);
      setPending(null);
    }
  };
  return <Box mt={supportsEmailProvider(authProviders) ? 4 : 0} mb={2} display="flex" flexDirection="column" gap={1} data-sentry-element="Box" data-sentry-component="SSOClient" data-sentry-source-file="SSOClient.tsx">
      {!supportsEmailProvider(authProviders) && <Button variant="outlined" ordinal="secondary" onClick={() => {
      router.push(`${pathname}${next ? `?next=${next}` : ''}`);
      reset();
    }}>
          <FormattedMessage id="auth.change-email" />
        </Button>}
      {authProviders.map(provider => {
      if (provider === 'email') return null;
      return <Box key={provider}>
            <LoadingButton fullWidth hideContentOnLoad variant="outlined" ordinal="secondary" onClick={() => {
          onClick(provider);
          const key = page === 'signin' ? 'SignIn:External' : 'SignUp:External';
          analyticsTrack(key, {
            type: provider
          });
        }} disabled={pending === provider} loading={pending === provider} sx={{
          height: '44px',
          textTransform: 'none'
        }}>
              <Box display="flex" alignContent="center" alignItems="center" justifyContent="center" gap={1}>
                {providerImageMap[provider]}
                <Typography variant="subtitle1" color="text.primary">
                  <FormattedMessage id={buttonAction} /> <FormattedMessage id="auth.with" /> {friendlyProviderName(provider)}
                </Typography>
              </Box>
            </LoadingButton>
          </Box>;
    })}
    </Box>;
};